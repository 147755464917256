var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetEnginePanelHeaderAtomStyled', [_c('div', {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _vm.timeScope ? _c('div', {
    staticClass: "time-scope"
  }, [_c('div', {
    staticClass: "badge"
  }, [_vm._v(" " + _vm._s(_vm.timeScope) + " ")])]) : _vm._e(), _vm.isMultiple ? _c('div', {
    staticClass: "aggregators"
  }, [_vm._v(_vm._s(_vm.$t('molecules.assetEngine.aggregators')))]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }