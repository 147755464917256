var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AlternatingListMolecule', {
    attrs: {
      "items": _vm.itemsWithData,
      "title": _vm.assetEnginePanel.label
    }
  }, [_c('AssetEnginePanelHeaderAtom', {
    attrs: {
      "title": _vm.assetEnginePanel.label,
      "timeScope": _vm.$t('timeScopes.lastDay'),
      "isMultiple": true
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }