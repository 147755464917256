var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AlternatingListMoleculeStyled', [_vm._t("default", function () {
    return [_c('header', [_c('span', {
      staticClass: "title-fallback"
    }, [_vm._v(" " + _vm._s(_vm.title) + " ")])])];
  }), _c('main', _vm._l(_vm.items, function (item) {
    return _c('AlternatingListItemAtom', {
      key: item.id,
      attrs: {
        "item": item
      }
    });
  }), 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }