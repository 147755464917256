<template>
  <TableWrapper>
    <TableV2
      class="tyre-status"
      :title="$tc('tyre', 2)"
      :headers="headers"
      :rows="rows"
      :sorting.sync="sorting"
      :filtersAvailable="filtersAvailable"
      :filterOptionsSelected="filterOptionsSelected"
      :isLoading="$apollo.loading"
      :searchQuery="searchQuery"
      :enableSearch="enableSearch"
      :messages="messages"
    />
  </TableWrapper>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import round from '../../../../utils/round'
import TableV2 from '@/components/Atomic/Molecules/TableV2.vue'
import { TABLE_SORT_TYPES } from '@/constants/settings'
import TyrePositionIcon from '@/components/Atomic/Atoms/TyrePositionIcon.vue'
import TyreHealthStatusIcon from '@/components/Atomic/Atoms/TyreHealthStatusIcon.vue'
import TyreMaintenanceIcon from '@/components/Atomic/Atoms/TyreMaintenanceIcon.vue'

import ASSET_WITH_WHEELPOSITION_QUERY from '#/graphql/operations/asset/wheelposition/assetWheelPositionsMounted.gql'
import { concat, orderBy, partition } from 'lodash'

const TableWrapper = styled('div')`
  height: 100%;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.atomic.chartCardBG};
`
export default {
  inject: ['permissions'],
  props: {
    route: {
      type: Object,
      required: true,
    },
  },
  components: {
    TableV2,
    TableWrapper,
  },
  data() {
    return {
      wheelPositions: [],
      sorting: {
        key: 'type.name',
        asc: true,
      },
      searchQuery: '',
      filtersAvailable: [],
      filterOptionsSelected: [],
      enableSearch: false,
    }
  },
  computed: {
    hasTyreReadPermission() {
      return get(this.permissions, 'read', []).find(p => p.name === 'tyre_read')
    },
    assetID() {
      return get(this.route, 'params.id', null)
    },
    headers() {
      return [
        {
          id: 'header_name',
          label: this.$tc('tyres.name', 1).toUpperCase(),
          sortType: TABLE_SORT_TYPES.A_TO_Z,
          isSortable: true,
          sortKey: 'tyreName',
          size: 'small',
        },
        {
          id: 'header_model',
          label: this.$tc('model', 1).toUpperCase(),
          sortType: TABLE_SORT_TYPES.A_TO_Z,
          isSortable: true,
          sortKey: 'tyreModel',
          size: 'small',
        },
        {
          id: 'header_position',
          label: this.$tc('tyres.position', 1).toUpperCase(),
          sortType: TABLE_SORT_TYPES.FRONT_TO_BACK,
          isSortable: true,
          sortKey: 'axlePosition',
          size: 'small',
        },
        {
          id: 'header_pressure',
          label: this.$t('tyres.airPressure').toUpperCase(),
          unit: 'BAR',
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'pressure',
          size: 'small',
        },
        {
          id: 'header_temperature',
          label: this.$tc('tyres.temperature', 1).toUpperCase(),
          unit: '°C',
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'temperature',
          size: 'small',
        },
        {
          id: 'header_tyreHealth',
          label: this.$tc('tyres.tyreHealth', 1).toUpperCase(),
          sortType: TABLE_SORT_TYPES.OK_TO_ERROR,
          isSortable: true,
          sortKey: 'overallTyreHealth.id',
          size: 'small',
        },
        {
          id: 'header_maintenance',
          label: this.$tc('tyres.maintenance', 1).toUpperCase(),
          size: 'small',
        },
      ]
    },
    mappedWheelPositionsData() {
      const filterdWheelPositions = this.wheelPositions.filter(wheel => wheel.unmountedAt === null)
      const wheelPositionsData = filterdWheelPositions.map(wheelPosition => {
        const wheel = get(wheelPosition, 'wheel', {})
        const tyreFits = get(wheel, 'tyreFits', []).find(tyre => tyre.unfittedAt === null)
        const tyre = get(tyreFits, 'tyre', {})
        const temperatureServerity = get(wheelPosition, 'temperatureThreshold.severity', '')
        const pressureThresholdSeverity = get(wheelPosition, 'pressureThreshold.severity', '')
        const tyreHealth = this.mappedSeverity(temperatureServerity, pressureThresholdSeverity)
        const colorsOrderMap = [
          { color: 'Red', value: 3 },
          { color: 'Yellow', value: 2 },
          { color: 'Green', value: 1 },
        ]
        return {
          id: get(wheelPosition, 'id', ''),
          axlePosition: get(wheelPosition, 'axlePosition', 0),
          tyreName: get(tyre, 'serialNumber', '--'),
          tyreModel: get(tyre, 'model', '--'),
          temperature: get(wheelPosition, 'temperature', 0),
          pressure: get(wheelPosition, 'pressure', 0),
          overallTyreHealth: {
            id:
              get(
                colorsOrderMap.find(({ color }) => color === tyreHealth),
                'value',
              ) || 0,
            status: tyreHealth,
          },
          maintenance: {
            pressure: pressureThresholdSeverity,
            temperature: temperatureServerity,
          },
        }
      })
      return wheelPositionsData
    },
    tyresSorted() {
      const partitions = partition(this.mappedWheelPositionsData, x => !!get(x, this.sorting.key, null))
      const sortDirection = this.sorting.asc ? 'asc' : 'desc'
      return concat(orderBy(partitions[0], [this.sorting.key], [sortDirection]), partitions[1])
    },
    rows() {
      return this.tyresSorted.map(tyre => {
        const cells = [
          {
            id: `${tyre.id}name`,
            sortableValue: tyre.tyreName,
            headerId: 'header_name',
            isMobilePrimarycol: true,
            mobilePrimarycolLabel: tyre.tyreName,
          },
          {
            id: `${tyre.id}model`,
            sortableValue: tyre.tyreModel,
            headerId: 'header_model',
            isMobilePrimarycol: true,
            mobilePrimarycolLabel: tyre.tyreModel,
          },
          {
            id: `${tyre.id}position`,
            headerId: `header_position`,
            sortableValue: tyre.axlePosition.toString(),
            axelPosition: tyre.axlePosition.toString(),
            wheelCount: this.wheelPositions?.length ?? 0,
            component: TyrePositionIcon,
          },
          {
            id: `${tyre.id}pressure`,
            sortableValue: tyre.pressure === null ? '--' : round(this.converter(tyre.pressure) / 100, 2),
            headerId: 'header_pressure',
          },
          {
            id: `${tyre.id}temperature`,
            sortableValue: tyre.temperature === null ? '--' : round(this.converter(tyre.temperature), 1),
            headerId: 'header_temperature',
          },
          {
            id: `${tyre.id}health`,
            sortableValue: tyre?.overallTyreHealth?.id,
            component: TyreHealthStatusIcon,
            status: tyre?.overallTyreHealth?.status,
            headerId: 'header_tyreHealth',
          },
          {
            id: `${tyre.id}maintenance`,
            component: TyreMaintenanceIcon,
            status: tyre.maintenance,
            headerId: 'header_maintenance',
          },
        ]
        return {
          rowId: `row_${tyre.id}`,
          cells,
        }
      })
    },
    messages() {
      let message = ''
      if (this.rows.length === 0) {
        message = this.searchQuery === '' ? this.$tc('messages.noData') : this.$t('messages.searchNoResults', { query: this.searchQuery })
      }
      return message
    },
  },
  methods: {
    resetFilters() {
      this.filterOptionsSelected = []
    },
    textSearch(query) {
      this.searchQuery = query
    },
    converter(value) {
      const convertedValue = value > 0 || value !== null ? value.toString() : '--'
      return convertedValue
    },
    mappedSeverity(temperatureServerity, pressureThresholdSeverity) {
      const combinedSeverity = []
      combinedSeverity.push(temperatureServerity === '' ? 'UNKNOWN' : temperatureServerity)
      combinedSeverity.push(pressureThresholdSeverity === '' ? 'UNKNOWN' : pressureThresholdSeverity)
      if (combinedSeverity.includes('UNKNOWN')) {
        return 'Unknown'
      } else if (
        combinedSeverity.includes('CRITICAL') ||
        combinedSeverity.includes('CRITICALLY_HIGH') ||
        combinedSeverity.includes('CRITICALLY_LOW')
      ) {
        return 'Red'
      } else if (combinedSeverity.includes('HIGH') || combinedSeverity.includes('LOW')) {
        return 'Yellow'
      } else {
        return 'Green'
      }
    },
  },
  apollo: {
    wheelPositions: {
      query: ASSET_WITH_WHEELPOSITION_QUERY,
      variables() {
        return {
          id: this.assetID,
        }
      },
      update: data => data?.assetWheelPositions || [],
      skip() {
        return !this.assetID || !this.hasTyreReadPermission
      },
    },
  },
}
</script>
