<template>
  <AlternatingListMolecule :items="itemsWithData" :title="assetEnginePanel.label">
    <AssetEnginePanelHeaderAtom :title="assetEnginePanel.label" :timeScope="$t('timeScopes.lastDay')" :isMultiple="true" />
  </AlternatingListMolecule>
</template>

<script>
import { DateTime } from 'luxon'

import round from '@/utils/round'

import AlternatingListMolecule from '../../../Molecules/AlternatingListMolecule.vue'
import AssetEnginePanelHeaderAtom from '../../../Atoms/AssetEnginePanelHeaderAtom.vue'
import SIGNAL_DATA_MULTIPLE_QUERY from '#/graphql/signals/signalDataMultiple.gql'

export default {
  props: {
    asset: {
      type: Object,
      required: true,
    },
    assetEnginePanel: {
      type: Object,
      required: true,
    },
  },
  components: {
    AlternatingListMolecule,
    AssetEnginePanelHeaderAtom,
  },
  data() {
    return {
      signalDataMultipleMin: [],
      signalDataMultipleAvg: [],
      signalDataMultipleMax: [],
    }
  },
  computed: {
    itemsWithData() {
      const items = this.assetEnginePanel?.items || []
      return items.map(item => {
        const min = this.signalDataMultipleMin.find(f => f.signalId === item.signal?.signalId)
        const avg = this.signalDataMultipleAvg.find(f => f.signalId === item.signal?.signalId)
        const max = this.signalDataMultipleMax.find(f => f.signalId === item.signal?.signalId)
        const minData = min?.data || []
        const minFiltered = minData.filter(f => f !== null)
        const minOfMins = minFiltered.length > 0 ? Math.min(...minFiltered) : null
        const avgData = avg?.data || []
        const avgFiltered = avgData.filter(f => f !== null)
        const avgOfAvgs = avgFiltered.length > 0 ? avgFiltered.reduce((a, i) => a + i, 0) / avgFiltered.length : null
        const maxData = max?.data || []
        const maxFiltered = maxData.filter(f => f !== null)
        const maxOfMaxs = minFiltered.length > 0 ? Math.max(...maxFiltered) : null
        const isPressure = item?.unit === 'kPa'
        const data = [
          minOfMins !== null ? (isPressure ? minOfMins.toFixed(1) : round(minOfMins, 0)) : null,
          avgOfAvgs !== null ? (isPressure ? avgOfAvgs.toFixed(1) : round(avgOfAvgs, 0)) : null,
          maxOfMaxs !== null ? (isPressure ? maxOfMaxs.toFixed(1) : round(maxOfMaxs, 0)) : null,
        ]
        return {
          ...item,
          isLoading: this.$apollo.loading,
          data,
        }
      })
    },
    timeRange() {
      const firstTimestamp = this.assetEnginePanel.uniqTimestamps?.keys()?.next()?.value
      if (!firstTimestamp) {
        return null
      }
      const to = DateTime.fromISO(firstTimestamp).toUTC().startOf('minute')
      const timeRange = {
        from: to.minus({ hours: 24 }).toUTC().toISO(),
        to: to.plus({ minutes: 1 }).toUTC().toISO(),
      }
      return timeRange
    },
    sampleRate() {
      return 3600
    },
    columnsMin() {
      const columns = this.assetEnginePanel.items
        .filter(item => item.signal?.signalId !== undefined && item.isEnabled)
        .map(item => ({
          signalId: item.signal.signalId,
          aggregator: 'MIN',
        }))
      return columns
    },
    columnsAvg() {
      const columns = this.assetEnginePanel.items
        .filter(item => item.signal?.signalId !== undefined && item.isEnabled)
        .map(item => ({
          signalId: item.signal.signalId,
          aggregator: 'AVG',
        }))
      return columns
    },
    columnsMax() {
      const columns = this.assetEnginePanel.items
        .filter(item => item.signal?.signalId !== undefined && item.isEnabled)
        .map(item => ({
          signalId: item.signal.signalId,
          aggregator: 'MAX',
        }))
      return columns
    },
  },
  apollo: {
    signalDataMultipleMin: {
      query: SIGNAL_DATA_MULTIPLE_QUERY,
      variables() {
        return {
          where: {
            assetId: this.asset.id,
            timeRange: this.timeRange,
            columns: this.columnsMin,
            sampleRate: this.sampleRate,
          },
        }
      },
      skip() {
        return !this.asset.id || !this.timeRange?.to || !this.columnsMin || this.columnsMin.length < 1
      },
      update: ({ signalDataMultiple }) => signalDataMultiple,
    },
    signalDataMultipleAvg: {
      query: SIGNAL_DATA_MULTIPLE_QUERY,
      variables() {
        return {
          where: {
            assetId: this.asset.id,
            timeRange: this.timeRange,
            columns: this.columnsAvg,
            sampleRate: this.sampleRate,
          },
        }
      },
      skip() {
        return !this.asset.id || !this.timeRange?.to || !this.columnsAvg || this.columnsAvg.length < 1
      },
      update: ({ signalDataMultiple }) => signalDataMultiple,
    },
    signalDataMultipleMax: {
      query: SIGNAL_DATA_MULTIPLE_QUERY,
      variables() {
        return {
          where: {
            assetId: this.asset.id,
            timeRange: this.timeRange,
            columns: this.columnsMax,
            sampleRate: this.sampleRate,
          },
        }
      },
      skip() {
        return !this.asset.id || !this.timeRange?.to || !this.columnsMax || this.columnsMax.length < 1
      },
      update: ({ signalDataMultiple }) => signalDataMultiple,
    },
  },
}
</script>
