<template>
  <AssetNoEngineStyled v-if="!hasEnginePanelPermission">
    <div class="message">
      {{ $tc('messages.tyreNotAvailableMsg') }}
    </div>
  </AssetNoEngineStyled>
  <AssetNoEngineStyled v-else-if="!showEngine">
    <TalpaLoaderWrapper class="loader-wrapper" v-if="$apollo.queries.signalsByAsset.loading" />
    <div class="message" v-else>
      {{ $t('molecules.assetEngine.noEngineData') }}
    </div>
  </AssetNoEngineStyled>
  <AssetEngineStyled v-else>
    <template v-for="assetEnginePanel in assetEnginePanels">
      <AssetEnginePanelLatestOrganism
        v-if="assetEnginePanel.type === 'latest'"
        :key="assetEnginePanel.id"
        :assetEnginePanel="assetEnginePanel"
        :asset="asset"
      />
      <AssetEnginePanelMaxOrganism
        v-if="assetEnginePanel.type === 'max'"
        :key="assetEnginePanel.id"
        :assetEnginePanel="assetEnginePanel"
        :asset="asset"
      />
      <AssetEnginePanelMinAvgMaxOrganism
        v-if="assetEnginePanel.type === 'min_avg_max'"
        :key="assetEnginePanel.id"
        :assetEnginePanel="assetEnginePanel"
        :asset="asset"
      />
    </template>
  </AssetEngineStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { DateTime } from 'luxon'

import AssetEnginePanelLatestOrganism from './AssetEnginePanelLatestOrganism.vue'
import AssetEnginePanelMaxOrganism from './AssetEnginePanelMaxOrganism.vue'
import AssetEnginePanelMinAvgMaxOrganism from './AssetEnginePanelMinAvgMaxOrganism.vue'
import { TalpaLoaderWrapper } from '@common/components'
import { staticLists } from './staticSignalLists'

import SIGNALS_BY_ASSET_QUERY from '#/graphql/signalsByAssetData/signalsByAsset.gql'
import SIGNAL_AGGREGATION_CONFIGS_BY_ASSET_QUERY from '#/graphql/misc/signalAggregationConfigsByAsset.gql'

const AssetEngineStyled = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: min-content;
  grid-gap: 1rem;
  align-items: stretch;
`

const AssetNoEngineStyled = styled('div')`
  background: ${({ theme }) => theme.colors.atomic.tableBG};
  min-height: 225px;
  display: flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  .message {
    height: 100%;
  }
`

export default {
  props: {
    asset: {
      type: Object,
      required: true,
    },
    hasEnginePanelPermission: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    AssetEngineStyled,
    AssetEnginePanelLatestOrganism,
    AssetEnginePanelMaxOrganism,
    AssetEnginePanelMinAvgMaxOrganism,
    AssetNoEngineStyled,
    TalpaLoaderWrapper,
  },
  data() {
    return {
      signalsByAsset: [],
      signalAggregationConfigsByAsset: [],
    }
  },
  computed: {
    assetEnginePanels() {
      const signalsByAsset = this.signalsByAsset
      const signalAggregationConfigs = this.signalAggregationConfigsByAsset
      const mapped = staticLists.map(list => {
        return {
          id: list.id,
          type: list.type,
          label: this.$t('molecules.assetEngine.' + list.id),
          items: list.items.map(item => {
            let isEnabled = false
            const found = signalsByAsset.find(f => f.shortName === item.id)
            const foundAggregation = found
              ? signalAggregationConfigs.find(
                  aggregationConfig =>
                    aggregationConfig.signalIdOrName === found.shortName || aggregationConfig.signalIdOrName === found.signalId,
                )
              : undefined
            if (foundAggregation) {
              if (foundAggregation.sampleRates.includes('60000')) {
                if (list.type === 'latest') {
                  isEnabled = foundAggregation.aggregations.includes('last')
                }
                if (list.type === 'max') {
                  isEnabled = foundAggregation.aggregations.includes('max')
                }
                if (list.type === 'min_avg_max') {
                  isEnabled =
                    foundAggregation.aggregations.includes('min') &&
                    foundAggregation.aggregations.includes('max') &&
                    foundAggregation.aggregations.includes('count') &&
                    foundAggregation.aggregations.includes('sum')
                }
              }
            }
            return {
              id: item.id,
              unit: item.unit,
              label: this.$t('molecules.assetEngine.signals.' + item.id),
              isEnabled,
              signal: found,
              transformValues: item.transformValues ? item.transformValues : null,
            }
          }),
          uniqTimestamps: list.items.reduce((acc, item) => {
            const found = signalsByAsset.find(f => f.shortName === item.id)
            if (found) {
              const validTillZ = DateTime.fromISO(found.validTill).toUTC()
              if (acc.has(validTillZ)) {
                acc.get(validTillZ).push(item.id)
              } else {
                acc.set(validTillZ, [item.id])
              }
            }
            return acc
          }, new Map()),
        }
      })

      return mapped.map(panel => {
        panel.items = panel.items.filter(sensor => sensor.isEnabled)
        return {
          ...panel,
        }
      })
    },
    showEngine() {
      const value = this.assetEnginePanels.reduce((isAnySensorFromEngineEnabled, panel) => {
        const hasAnySensorsEnabled = panel.items.reduce((isAnySensorFromPanelEnabled, sensor) => {
          if (sensor.label !== this.$t('totalMachineHours')) {
            return isAnySensorFromPanelEnabled || sensor.isEnabled
          }
        }, false)
        return isAnySensorFromEngineEnabled || hasAnySensorsEnabled
      }, false)
      return value
    },
  },
  apollo: {
    signalsByAsset: {
      query: SIGNALS_BY_ASSET_QUERY,
      variables() {
        return {
          assetId: this.asset.id,
        }
      },
      skip() {
        return !this.asset.id || !this.hasEnginePanelPermission
      },
    },
    signalAggregationConfigsByAsset: {
      query: SIGNAL_AGGREGATION_CONFIGS_BY_ASSET_QUERY,
      variables() {
        return {
          assetId: this.asset.id,
        }
      },
      skip() {
        return !this.asset.id || !this.hasEnginePanelPermission
      },
    },
  },
}
</script>
