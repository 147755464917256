<template>
  <Wrapper>
    <TalpaIcon :isCustomColor="true" :scope="'Tyre'" :name="'AssetTyrePositionLarge'" :class="classGenerator" v-tooltip="tooltip" />
  </Wrapper>
</template>

<script>
import { TalpaIcon } from '@common/components'
import { styled } from '@egoist/vue-emotion'
import { get } from 'lodash'
import { VTooltip } from 'v-tooltip'

const Wrapper = styled('div')`
  display: flex;
  padding: 0.5rem 0.5rem 0.5rem 3rem;
  align-items: center;
  > span {
    padding-left: 1rem;
    font-size: 14px;
    width: 6.5rem;
    word-break: break-all;
  }
  > div,
  svg.customColor {
    width: 45px !important;
    height: 35px !important;
    fill: ${({ theme }) => theme.colors.atomic.iconBG};
  }
  .front1 {
    svg.customColor {
      path#front1 {
        fill: ${({ theme }) => theme.colors.primary};
      }
    }
  }
  .front2 {
    svg.customColor {
      path#front2 {
        fill: ${({ theme }) => theme.colors.primary};
      }
    }
  }
  .rear1 {
    svg.customColor {
      path#rear1 {
        fill: ${({ theme }) => theme.colors.primary};
      }
    }
  }
  .rear2 {
    svg.customColor {
      path#rear2 {
        fill: ${({ theme }) => theme.colors.primary};
      }
    }
  }
  .rear3 {
    svg.customColor {
      path#rear3 {
        fill: ${({ theme }) => theme.colors.primary};
      }
    }
  }
  .rear4 {
    svg.customColor {
      path#rear4 {
        fill: ${({ theme }) => theme.colors.primary};
      }
    }
  }
  .wheels4 {
    svg.customColor {
      path#rear4 {
        display: none;
      }
    }
    svg.customColor {
      path#rear1 {
        display: none;
      }
    }
    &.rear1,
    &.rear2 {
      svg.customColor {
        path#rear2 {
          fill: ${({ theme }) => theme.colors.primary};
        }
      }
    }
    &.rear3,
    &.rear4 {
      svg.customColor {
        path#rear3 {
          fill: ${({ theme }) => theme.colors.primary};
        }
      }
    }
  }
`
export default {
  directives: {
    tooltip: VTooltip,
  },
  props: {
    cell: {
      type: Object,
      required: true,
    },
  },
  components: {
    Wrapper,
    TalpaIcon,
  },
  data() {
    return {
      large: true,
    }
  },
  computed: {
    axel() {
      return get(this.cell, 'axelPosition', '')
    },
    classGenerator() {
      return [
        { front1: this.axel === '11' },
        { front2: this.axel === '12' },
        { rear1: this.axel === '21' },
        { rear2: this.axel === '22' },
        { rear3: this.axel === '23' },
        { rear4: this.axel === '24' },
        { wheels4: this.cell?.wheelCount === 4 },
      ]
    },
    tooltip() {
      return {
        content: this.toolTipContent,
        placement: 'bottom',
        classes: 'dashboard',
        trigger: window.innerWidth < 1024 ? 'click' : 'hover',
      }
    },
    toolTipContent() {
      return this.axel && this.axel !== '' ? this.$tc(`tyres.axelPosition.${this.axel}`) : 'Not Available'
    },
  },
  methods: {
    onResize() {
      if (window.innerWidth < 1024) {
        this.large = false
      } else {
        this.large = true
      }
    },
  },
  mounted() {
    this.onResize()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
}
</script>
