var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IconWrapper', [_c('TalpaIcon', {
    attrs: {
      "isCustomColor": true,
      "scope": 'HealthStatusShort',
      "name": _vm.status === 'Unknown' ? 'Green' : _vm.status
    }
  }), _vm.large ? _c('span', [_vm._v(_vm._s(_vm.healthStatus))]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }