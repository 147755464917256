<template>
  <AlternatingListMolecule :items="itemsWithData" :title="assetEnginePanel.label">
    <AssetEnginePanelHeaderAtom :title="assetEnginePanel.label" :timeScope="$t('timeScopes.lastDay')" />
  </AlternatingListMolecule>
</template>

<script>
import { DateTime } from 'luxon'

import AlternatingListMolecule from '../../../Molecules/AlternatingListMolecule.vue'
import AssetEnginePanelHeaderAtom from '../../../Atoms/AssetEnginePanelHeaderAtom.vue'
import SIGNAL_DATA_MULTIPLE_QUERY from '#/graphql/signals/signalDataMultiple.gql'

export default {
  props: {
    asset: {
      type: Object,
      required: true,
    },
    assetEnginePanel: {
      type: Object,
      required: true,
    },
  },
  components: {
    AlternatingListMolecule,
    AssetEnginePanelHeaderAtom,
  },
  data() {
    return {
      signalDataMultiple: [],
    }
  },
  computed: {
    itemsWithData() {
      const items = this.assetEnginePanel?.items || []
      return items.map(item => {
        const found = this.signalDataMultiple.find(f => f.signalId === item.signal?.signalId)
        let nonNull = found?.data.filter(f => f !== null) || []
        const hasTransform = typeof item.transformValues === 'function'
        if (nonNull.length > 0 && hasTransform) {
          nonNull = item.transformValues(nonNull)
        }
        return {
          ...item,
          isLoading: this.$apollo.queries.signalDataMultiple.loading,
          data: nonNull.length > 0 ? [Math.max(...nonNull)] : [],
        }
      })
    },
    timeRange() {
      const firstTimestamp = this.assetEnginePanel.uniqTimestamps?.keys()?.next()?.value
      if (!firstTimestamp) {
        return null
      }
      const to = DateTime.fromISO(firstTimestamp).toUTC().startOf('minute')
      const timeRange = {
        from: to.minus({ hours: 24 }).toUTC().toISO(),
        to: to.plus({ minutes: 1 }).toUTC().toISO(),
      }
      return timeRange
    },
    sampleRate() {
      return 3600
    },
    columns() {
      const columns = this.assetEnginePanel.items
        .filter(item => item.signal?.signalId !== undefined && item.isEnabled)
        .map(item => ({
          signalId: item.signal.signalId,
          aggregator: 'MAX',
        }))
      return columns
    },
  },
  apollo: {
    signalDataMultiple: {
      query: SIGNAL_DATA_MULTIPLE_QUERY,
      variables() {
        return {
          where: {
            assetId: this.asset.id,
            timeRange: this.timeRange,
            columns: this.columns,
            sampleRate: this.sampleRate,
          },
        }
      },
      skip() {
        return !this.asset.id || !this.timeRange?.to || !this.columns || this.columns.length < 1
      },
    },
  },
}
</script>
