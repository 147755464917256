<template>
  <AlternatingListMoleculeStyled>
    <slot>
      <header>
        <span class="title-fallback">
          {{ title }}
        </span>
      </header>
    </slot>
    <main>
      <AlternatingListItemAtom v-for="item in items" :key="item.id" :item="item" />
    </main>
  </AlternatingListMoleculeStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import AlternatingListItemAtom from '../Atoms/AlternatingListItemAtom.vue'

const AlternatingListMoleculeStyled = styled('div')`
  background: ${({ theme }) => theme.colors.atomic.tableBG};
  border-radius: 0.5rem;
  padding: 0.5rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(2.5rem, min-content);
  > header > .title-fallback {
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.atomic.pinkSwan};
    padding: 0.25rem;
  }
  > main > div:nth-child(2n) {
    background: ${({ theme }) => (theme.isDark ? theme.colors.atomic.bunker : theme.colors.whiteSmokeDark)};
  }
`

export default {
  props: {
    title: {
      type: String,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  components: {
    AlternatingListMoleculeStyled,
    AlternatingListItemAtom,
  },
}
</script>
