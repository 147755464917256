var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TableWrapper', [_c('TableV2', {
    staticClass: "tyre-status",
    attrs: {
      "title": _vm.$tc('tyre', 2),
      "headers": _vm.headers,
      "rows": _vm.rows,
      "sorting": _vm.sorting,
      "filtersAvailable": _vm.filtersAvailable,
      "filterOptionsSelected": _vm.filterOptionsSelected,
      "isLoading": _vm.$apollo.loading,
      "searchQuery": _vm.searchQuery,
      "enableSearch": _vm.enableSearch,
      "messages": _vm.messages
    },
    on: {
      "update:sorting": function updateSorting($event) {
        _vm.sorting = $event;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }