<template>
  <IconWrapper>
    <TalpaIcon :isCustomColor="true" :scope="'HealthStatusShort'" :name="status === 'Unknown' ? 'Green' : status" />
    <span v-if="large">{{ healthStatus }}</span>
  </IconWrapper>
</template>

<script>
import { TalpaIcon } from '@common/components'
import { styled } from '@egoist/vue-emotion'
import { get } from 'lodash'

const IconWrapper = styled('div')`
  padding: 0.5rem 0.5rem 0.5rem 3rem;
  display: flex;
  align-items: center;
  > span {
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
    width: 8.5rem;
    word-break: break-all;
    @media (max-width: 768px) {
      width: 5rem;
    }
  }
  > div,
  svg.customColor {
    width: 16px;
    height: 16px;
  }
`
export default {
  props: {
    cell: {
      type: Object,
      required: true,
    },
  },
  components: {
    TalpaIcon,
    IconWrapper,
  },
  data() {
    return {
      large: true,
    }
  },
  computed: {
    healthStatus() {
      return this.status ? `${this.$tc('assetHealthStatus.' + this.status)}` : ''
    },
    status() {
      return get(this.cell, 'status', null)
    },
  },
  methods: {
    onResize() {
      if (window.innerWidth < 1024) {
        this.large = false
      } else {
        this.large = true
      }
    },
  },
  mounted() {
    this.onResize()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
}
</script>
