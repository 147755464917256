<template>
  <WrapperStyled>
    <div class="container">
      <div class="message">
        <div class="title">
          {{ title }}
          <div v-if="needNewIcon">{{ $t('messages.new') }}</div>
        </div>
        <div class="middle-msg">
          {{ message }}
        </div>
        <div class="actions">
          <a href="mailto:support@talpa-solutions.com" class="mail button">{{ button1Text }}</a>
          <div class="button" @click="$emit('accepted')">{{ button2Text }}</div>
        </div>
      </div>
      <div v-if="imageSrc" class="image">
        <img :src="imageSrc" />
      </div>
    </div>
  </WrapperStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import { flexCenter } from '@styles/mixins'

const WrapperStyled = styled('div')`
  background: ${({ theme }) => theme.colors.atomic.chartCardBG};
  border-radius: 10px;
  .container {
    display: flex;
    flex-direction: row;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    .message {
      padding: 16px;
      width: 45%;
      .title {
        font-size: 24px;
        letter-spacing: 0.18px;
        color: ${({ theme }) => theme.colors.atomic.title1};
        display: flex;
        div {
          ${flexCenter}
          padding: 6px;
          font-size: 10px;
          border-radius: 10px;
          background: ${({ theme }) => theme.colors.atomic.bunkerLight};
          margin-left: 8px;
        }
      }
      .middle-msg {
        padding: 20px 0px;
        font-size: 16px;
        letter-spacing: 0.5px;
        color: ${({ theme }) => theme.colors.atomic.title2};
      }
      .actions {
        display: flex;
        flex-direction: row;
        font-size: 12px;
        letter-spacing: 0px;

        .button {
          background: ${({ theme }) => theme.colors.atomic.bunkerLight};
          padding: 8px;
          color: ${({ theme }) => theme.colors.atomic.title2};
          padding: 8px;
          border-radius: 5px;
          cursor: pointer;
        }
        .mail {
          text-decoration: none;
          margin-right: 16px;
          background: ${({ theme }) => (theme.isDark ? theme.colors.atomic.violet : theme.colors.atomic.turbo)};
        }
      }
    }
    .image {
      border-radius: 10px;
      display: flex;
      width: 800px;
      overflow: hidden;
      justify-content: end;
      img {
        border-radius: 10px;
      }
    }

    @media (max-width: 1024px) {
      .image {
        width: 600px;
      }
    }
    @media (max-width: 768px) {
      flex-direction: column;
      .message {
        width: 100%;
      }
      .image {
        width: 500px;
      }
    }
    @media (max-width: 425px) {
      flex-direction: column;
      .message {
        width: 100%;
      }
      .image {
        width: 320px;
      }
    }
  }
`

export default {
  inject: ['theme'],
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    needNewIcon: {
      type: Boolean,
      default: true,
    },
    button1: {
      type: String,
      default: '',
    },
    button2: {
      type: String,
      default: '',
    },
    imageSrc: {
      type: String,
      default: null,
    },
  },
  components: {
    WrapperStyled,
  },
  data() {
    return {}
  },
  computed: {
    button1Text() {
      if (this.button1 === '') {
        return this.$t('actions.contactUs')
      }
      return this.button1
    },
    button2Text() {
      if (this.button2 === '') {
        return this.$t('actions.gotIt')
      }
      return this.button2
    },
  },
}
</script>
