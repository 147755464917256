var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AlternatingListItemAtomStyled', {
    class: {
      muted: !_vm.item.isEnabled
    }
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(" " + _vm._s(_vm.item.label) + " ")]), _vm.item.isEnabled && _vm.item.isLoading ? _c('div', {
    staticClass: "loading"
  }, [_c('LoadingDots')], 1) : _vm.item.isEnabled ? [_vm.hasData ? _c('div', {
    staticClass: "data"
  }, [_vm._l(_vm.item.data, function (value, i) {
    return _c('span', {
      key: i,
      staticClass: "value",
      class: {
        multiple: _vm.hasMultiple
      }
    }, [_vm._v(" " + _vm._s(value) + " ")]);
  }), _c('span', {
    staticClass: "unit"
  }, [_vm._v(" " + _vm._s(_vm.item.unit) + " ")])], 2) : _c('div', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.noDataTooltip,
      expression: "noDataTooltip"
    }],
    staticClass: "no-data"
  }, [_vm._v("-")])] : _c('div', {
    staticClass: "placeholder"
  }, [_vm._v("-")])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }