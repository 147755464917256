<template>
  <IconWrapper>
    <TalpaIcon
      :isCustomColor="true"
      :scope="'Tyre'"
      :name="'PressureIcon'"
      :class="{ active: pressureStatus }"
      v-tooltip="pressureStatus ? tooltip('pressure') : ''"
    />
    <TalpaIcon
      :isCustomColor="true"
      :scope="'Tyre'"
      :name="'TemperatureIcon'"
      :class="{ active: temperatureStatus }"
      v-tooltip="temperatureStatus ? tooltip('temperature') : ''"
    />
  </IconWrapper>
</template>

<script>
import get from 'lodash/get'
import { styled } from '@egoist/vue-emotion'
import { TalpaIcon } from '@common/components'
import { VTooltip } from 'v-tooltip'

const IconWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0.5rem 0.5rem 3rem;
  align-items: center;
  > div,
  svg.customColor {
    width: 24px;
    height: 24px;
    fill: ${({ theme }) => theme.colors.atomic.iconBG};
  }
  .active {
    svg.customColor {
      width: 24px;
      height: 24px;
      path {
        fill: ${({ theme }) => theme.colors.atomic.artylickOrange};
      }
    }
  }
`

export default {
  directives: {
    tooltip: VTooltip,
  },
  props: {
    cell: {
      type: Object,
      required: true,
    },
  },
  components: {
    TalpaIcon,
    IconWrapper,
  },
  computed: {
    maintenanceStatus() {
      return get(this.cell, 'status', {})
    },
    pressureStatus() {
      return get(this.maintenanceStatus, 'pressure', '') !== 'NORMAL'
    },
    temperatureStatus() {
      return get(this.maintenanceStatus, 'temperature', '') !== 'NORMAL'
    },
  },
  methods: {
    tooltip(indicator) {
      return {
        content: this.toolTipContent(indicator),
        placement: 'bottom',
        classes: 'dashboard',
        trigger: window.innerWidth < 1024 ? 'click' : 'hover',
      }
    },
    toolTipContent(mode) {
      if (mode === 'pressure') {
        const status = get(this.maintenanceStatus, 'pressure', '')
        if (status === 'LOW' || status === 'CRITICALLY_LOW') {
          return this.$tc('tyres.toolTip.lowPressure')
        } else if (status === '') {
          return this.$tc('tyres.toolTip.noSignal')
        } else {
          return this.$tc('tyres.toolTip.highPressure')
        }
      } else if (mode === 'temperature') {
        const status = get(this.maintenanceStatus, 'temperature', '')
        if (status) {
          return this.$tc('tyres.toolTip.highTemperature')
        } else {
          return this.$tc('tyres.toolTip.noSignal')
        }
      }
    },
  },
}
</script>
