var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Wrapper', [_c('TalpaIcon', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.tooltip,
      expression: "tooltip"
    }],
    class: _vm.classGenerator,
    attrs: {
      "isCustomColor": true,
      "scope": 'Tyre',
      "name": 'AssetTyrePositionLarge'
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }