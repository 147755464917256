<template>
  <AlternatingListItemAtomStyled :class="{ muted: !item.isEnabled }">
    <div class="label">
      {{ item.label }}
    </div>
    <div class="loading" v-if="item.isEnabled && item.isLoading">
      <LoadingDots />
    </div>
    <template v-else-if="item.isEnabled">
      <div class="data" v-if="hasData">
        <span class="value" :class="{ multiple: hasMultiple }" v-for="(value, i) in item.data" :key="i">
          {{ value }}
        </span>
        <span class="unit">
          {{ item.unit }}
        </span>
      </div>
      <div class="no-data" v-else v-tooltip="noDataTooltip">-</div>
    </template>
    <div class="placeholder" v-else>-</div>
  </AlternatingListItemAtomStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { LoadingDots } from '@common/components'
import { VTooltip } from 'v-tooltip'

const AlternatingListItemAtomStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-template-rows: minmax(2.5rem, min-content);
  align-items: center;
  font-size: 0.875rem;

  > div {
    padding: 0.5rem;
  }
  .data {
    display: flex;
    align-items: center;
    .value.multiple {
      margin: 0.25rem;
      background: ${({ theme }) => (theme.isDark ? theme.colors.atomic.divider : theme.colors.atomic.divider)};
      padding: 0.25rem;
      border-radius: 0.5rem;
    }
    .unit {
      margin-left: 0.5rem;
    }
  }
  .no-data {
    white-space: nowrap;
  }

  &.muted {
    color: ${({ theme }) => (theme.isDark ? theme.colors.atomic.osloGrey : theme.colors.muted)};
    .data .value.multiple {
      background: none;
    }
  }
`

export default {
  directives: {
    tooltip: VTooltip,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  components: {
    AlternatingListItemAtomStyled,
    LoadingDots,
  },
  computed: {
    hasData() {
      return this.item?.data?.length > 0 && this.item?.data?.some(s => s !== null)
    },
    hasMultiple() {
      return this.item.data.length > 1
    },
    noDataTooltip() {
      return {
        content: this.$t('messages.noSignalData'),
        placement: 'bottom',
        classes: 'dashboard',
        trigger: window.innerWidth < 1024 ? 'click' : 'hover',
      }
    },
  },
}
</script>
