var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.hasEnginePanelPermission ? _c('AssetNoEngineStyled', [_c('div', {
    staticClass: "message"
  }, [_vm._v(" " + _vm._s(_vm.$tc('messages.tyreNotAvailableMsg')) + " ")])]) : !_vm.showEngine ? _c('AssetNoEngineStyled', [_vm.$apollo.queries.signalsByAsset.loading ? _c('TalpaLoaderWrapper', {
    staticClass: "loader-wrapper"
  }) : _c('div', {
    staticClass: "message"
  }, [_vm._v(" " + _vm._s(_vm.$t('molecules.assetEngine.noEngineData')) + " ")])], 1) : _c('AssetEngineStyled', [_vm._l(_vm.assetEnginePanels, function (assetEnginePanel) {
    return [assetEnginePanel.type === 'latest' ? _c('AssetEnginePanelLatestOrganism', {
      key: assetEnginePanel.id,
      attrs: {
        "assetEnginePanel": assetEnginePanel,
        "asset": _vm.asset
      }
    }) : _vm._e(), assetEnginePanel.type === 'max' ? _c('AssetEnginePanelMaxOrganism', {
      key: assetEnginePanel.id,
      attrs: {
        "assetEnginePanel": assetEnginePanel,
        "asset": _vm.asset
      }
    }) : _vm._e(), assetEnginePanel.type === 'min_avg_max' ? _c('AssetEnginePanelMinAvgMaxOrganism', {
      key: assetEnginePanel.id,
      attrs: {
        "assetEnginePanel": assetEnginePanel,
        "asset": _vm.asset
      }
    }) : _vm._e()];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }