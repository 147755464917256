var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IconWrapper', [_c('TalpaIcon', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.pressureStatus ? _vm.tooltip('pressure') : '',
      expression: "pressureStatus ? tooltip('pressure') : ''"
    }],
    class: {
      active: _vm.pressureStatus
    },
    attrs: {
      "isCustomColor": true,
      "scope": 'Tyre',
      "name": 'PressureIcon'
    }
  }), _c('TalpaIcon', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.temperatureStatus ? _vm.tooltip('temperature') : '',
      expression: "temperatureStatus ? tooltip('temperature') : ''"
    }],
    class: {
      active: _vm.temperatureStatus
    },
    attrs: {
      "isCustomColor": true,
      "scope": 'Tyre',
      "name": 'TemperatureIcon'
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }