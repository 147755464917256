<template>
  <AssetEnginePanelHeaderAtomStyled>
    <div class="title">
      {{ title }}
    </div>
    <div class="time-scope" v-if="timeScope">
      <div class="badge">
        {{ timeScope }}
      </div>
    </div>
    <div class="aggregators" v-if="isMultiple">{{ $t('molecules.assetEngine.aggregators') }}</div>
  </AssetEnginePanelHeaderAtomStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const AssetEnginePanelHeaderAtomStyled = styled('div')`
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  grid-template-rows: min-content;
  grid-gap: 0.5rem;
  align-items: center;
  .title {
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.atomic.pinkSwan};
    padding: 0.25rem;
    white-space: nowrap;
  }
  .time-scope {
    display: flex;
    > .badge {
      margin: 0.25rem;
      background: ${({ theme }) => (theme.isDark ? theme.colors.atomic.healthStatusIconBG : theme.colors.whiteSmokeDark)};
      padding: 0.25rem;
      border-radius: 0.5rem;
      font-size: 0.75rem;
      text-transform: uppercase;
    }
  }
  .aggregators {
    white-space: nowrap;
    font-size: 0.75rem;
  }
`

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    timeScope: {
      type: String,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AssetEnginePanelHeaderAtomStyled,
  },
}
</script>
