export const staticLists = [
  {
    id: 'filling_load_levels',
    type: 'latest',
    items: [
      {
        id: 'engine__1_engine_coolant_level_1',
        unit: '%',
      },
      {
        id: 'exhaust_emission_controller_aftertreatment_1_diesel_exhaust_fluid_tank_volume',
        unit: '%',
      },
      {
        id: 'exhaust_emission_controller_aftertreatment_1_diesel_exhaust_fluid_tank_level',
        unit: 'mm',
      },
      {
        id: 'engine__1_aftertreatment_1_diesel_particulate_filter_soot_load_percent',
        unit: '%',
      },
      {
        id: 'engine__1_aftertreatment_1_diesel_particulate_filter_ash_load_percent',
        unit: '%',
      },
      {
        id: 'engine__1_engine_oil_life_remaining',
        unit: '%',
      },
      {
        id: 'engine__1_engine_oil_level',
        unit: '%',
      },
    ],
  },
  {
    id: 'counters',
    type: 'max',
    items: [
      {
        id: 'engine__1_engine_total_hours_of_operation',
        unit: 'h',
      },
      {
        id: 'engine__1_engine_total_fuel_used',
        unit: 'l',
      },
      {
        id: 'engine__1_aftertreatment_1_diesel_particulate_filter_time_since_last_active_regeneration',
        transformValues: values => {
          return values.map(value => Math.round((value / 3600) * 100) / 100)
        },
        unit: 'h',
      },
      {
        id: 'engine__1_engine_fuel_supply_estimated_remaining_distance',
        unit: 'km',
      },
    ],
  },
  {
    id: 'temperatures',
    type: 'min_avg_max',
    items: [
      {
        id: 'engine__1_engine_intake_manifold_1_temperature',
        unit: '°C',
      },
      {
        id: 'engine__1_engine_exhaust_temperature',
        unit: '°C',
      },
      {
        id: 'engine__1_engine_exhaust_temperature_2',
        unit: '°C',
      },
      {
        id: 'engine__1_engine_coolant_temperature',
        unit: '°C',
      },
      {
        id: 'exhaust_emission_controller_aftertreatment_1_diesel_exhaust_fluid_tank_temperature_1',
        unit: '°C',
      },
      {
        id: 'exhaust_emission_controller_aftertreatment_1_scr_intake_temperature',
        unit: '°C',
      },
      {
        id: 'engine__1_aftertreatment_1_diesel_oxidation_catalyst_intake_temperature',
        unit: '°C',
      },
      {
        id: 'engine__1_aftertreatment_1_diesel_oxidation_catalyst_outlet_temperature',
        unit: '°C',
      },
      {
        id: 'engine__1_ambient_air_temperature',
        unit: '°C',
      },
      {
        id: 'engine__1_engine_oil_temperature_1',
        unit: '°C',
      },
      {
        id: 'exhaust_emission_controller_aftertreatment_1_exhaust_temperature_1',
        unit: '°C',
      },
    ],
  },
  {
    id: 'pressures',
    type: 'min_avg_max',
    items: [
      {
        id: 'engine__1_engine_intake_manifold__1_pressure',
        unit: 'kPa',
      },
      {
        id: 'engine__1_engine_air_filter_1_differential_pressure',
        unit: 'kPa',
      },
      {
        id: 'engine__1_engine_fuel_delivery_pressure',
        unit: 'kPa',
      },
      {
        id: 'engine__1_engine_oil_pressure_1',
        unit: 'kPa',
      },
      {
        id: 'engine__1_aftertreatment_1_diesel_exhaust_fluid_doser_1_absolute_pressure',
        unit: 'kPa',
      },
      {
        id: 'engine__1_aftertreatment_1_diesel_particulate_filter_outlet_pressure',
        unit: 'kPa',
      },
      {
        id: 'engine__1_aftertreatment_1_diesel_particulate_filter_differential_pressure',
        unit: 'kPa',
      },
      {
        id: 'engine__1_engine_intake_air_pressure',
        unit: 'kPa',
      },
      {
        id: 'engine__1_engine_oil_filter_differential_pressure',
        unit: 'kPa',
      },
      {
        id: 'engine__1_aftertreatment_1_diesel_oxidation_catalyst_differential_pressure',
        unit: 'kPa',
      },
      {
        id: 'engine__1_barometric_pressure',
        unit: 'kPa',
      },
    ],
  },
]
